import styled from "styled-components";
import rtl from "styled-components-rtl";
import { colors } from "@netmedi/frontend-design-system/dist/styles/variables";
import rem from "@netmedi/frontend-design-system/dist/styles/styleHelpers";

export const SharedDocumentsTable = styled.div`
  width: 100%;
  ${rtl`text-align: left;`}
  padding: 20px 20px 10px 20px;

  .headerRow {
    font-weight: 500;
    ${rtl`padding-left: 15px;`}
    display: flex;
  }

  .contentRow {
    display: flex;
    border: 1px solid #dfdff6;
    border-radius: 12px;
    margin: 10px 0px;
    padding: 10px 15px;
    line-height: ${rem(22)};
  }

  .firstCol {
    flex: 1;
    ${rtl`padding-right: 5px;`}
  }
  .secondCol {
    flex: 3;
  }
  button {
    margin: 0px;
  }
`;

export const DisclaimerText = styled.div`
  > * {
    ${rtl`padding-right: 5px;`}
  }
  display: flex;
  color: ${colors.gray700};
`;

export const FileInputContainer = styled.div`
  width: 100%;
  padding: 0px 20px 10px 20px;
  display: flex;
  justify-content: space-between;
`;

export const NoFiles = styled.div.attrs(() => ({
  "data-testid": "no-files",
}))`
  margin: 15px 0px;
  color: ${colors.gray700};
`;

export const FileLink = styled.a.attrs(() => ({
  "data-testid": "file-link",
}))`
  color: ${colors.blue400};
  cursor: pointer;
  svg {
    ${rtl`margin-right: 5px;`}
  }
`;

export const FileDescription = styled.div`
  font-style: italic;
  margin-top: 5px;
`;

export const ButtonContainer = styled.div`
  flex-shrink: 0;
  ${rtl`margin: -5px 0px 10px 15px;`}
`;
