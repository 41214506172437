import React from "react";
import { FormattedDate, FormattedMessage, FormattedTime } from "react-intl";
import { Document } from "shared/components/DocumentCard/SharedDocumentCard";
import {
  SharedDocumentsTable,
  NoFiles,
  FileLink,
  FileDescription,
} from "./SharedDocuments.styles";
import { Icon } from "@netmedi/frontend-design-system";

export type SharedDocumentsProps = {
  documents: Document[];
  type: "shared-by-client" | "shared-to-client";
  descriptionHidden?: boolean;
};

const SharedDocuments = ({
  descriptionHidden = false,
  type,
  documents,
}: SharedDocumentsProps) => {
  const Table = () => {
    return (
      <div>
        {documents.map((d: Document) => (
          <div
            key={d.id}
            className="contentRow"
            data-testid={`${type}-documents-table`}
          >
            <span className="firstCol">
              <FormattedDate
                value={d.date}
                year="numeric"
                month="numeric"
                day="numeric"
              />
              {" – "}
              <FormattedTime value={d.date} />
            </span>
            <span className="secondCol">
              <FileLink target="_blank" href={d.url}>
                <Icon name="file" />
                <span>{d.name}</span>
              </FileLink>
              {!descriptionHidden && d.description ? (
                <FileDescription>{d.description}</FileDescription>
              ) : (
                d.sender && (
                  <FileDescription>
                    {d.sender + " ("}
                    <FormattedMessage id={`clients.domains.${d.domain}`} />
                    {")"}
                  </FileDescription>
                )
              )}
            </span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <SharedDocumentsTable>
      <div className="headerRow">
        <span className="firstCol">
          <FormattedMessage id="shared_documents.date_shared" />
        </span>
        <span className="secondCol">
          <FormattedMessage id="shared_documents.filename" />
          {!descriptionHidden && (
            <span>
              {" & "}
              <FormattedMessage id="shared_documents.description" />
            </span>
          )}
        </span>
      </div>
      {documents.length !== 0 ? (
        <Table />
      ) : (
        <NoFiles>
          <FormattedMessage id="shared_documents.no_files" />
        </NoFiles>
      )}
    </SharedDocumentsTable>
  );
};

export default SharedDocuments;
