/* eslint-disable max-lines */
import styled from "styled-components";
import rtl from "styled-components-rtl";
import {
  colors,
  scaffolding,
  zIndexes,
  mediaQueries,
  typography,
  tagsInConversation,
} from "@netmedi/frontend-design-system/dist/styles/variables";

import { Button, CustomModal } from "@netmedi/frontend-design-system";
import { mediaMax } from "@netmedi/frontend-design-system/dist/styles/mixins/mediaQueryMixins";

export const TextArea = styled.textarea`
  width: 100%;
`;

const modalStyles = `
  max-height: calc(99vh - 20%); // 10 % margin in top and bottom
  overflow: auto;
  max-width: 45em;
  white-space: pre-wrap;
`;

export const Modal = styled(CustomModal).attrs(() => ({
  "data-testid": "styled-modal",
}))`
  background: none;
  overflow: visible;
  & > div {
    ${modalStyles}
  }
`;

export const ModalLogo = styled.div`
  margin-top: 1em;
  text-align: center;
`;

export interface ModalNavProps {
  align?: "center" | "left" | "right";
}
export const ModalNav = styled.nav.attrs(() => ({
  "data-testid": "styled-nav-actions",
}))<ModalNavProps>`
  margin-top: 1em;
  ${({ align = "center" }) => `text-align: ${align}`};
`;

export const ModalNavFlex = styled(ModalNav)`
  display: flex;
  justify-content: space-around;
`;

export const ModalNavCarousel = styled.nav(rtl`
  ${mediaMax(812)} {
    text-align: center;
  }
  margin-top: 1em;
  text-align: right;
`);

export const CarouselSubmitButton = styled(Button).attrs(() => ({
  "data-testid-a": "styled-carousel-submit",
}))`
  ${mediaMax(812)} {
    width: 90%;
  }
`;

type ButtonDirection = { direction?: string };

export const ModalNavButton = styled(Button).attrs(
  ({ direction }: ButtonDirection) => ({
    "data-testid-a": direction || "styled-modal-nav-button",
  }),
)<ButtonDirection>`
  flex: 1 70px;
`;

export const Spacer = styled.div<{ flexNbr: number }>`
  ${({ flexNbr }) => `flex: ${flexNbr} 1`}
`;

export const ModalConsentModal = styled.div`
  position: relative;
  border: ${scaffolding.gridBorderColor} 1px solid;
  max-width: 60em;
  width: 90%;
  margin: 0 auto;
  z-index: ${zIndexes.modal};
  background: ${colors.white};
  animation: fadeIn 0.2s;
  position: relative;
  white-space: pre-wrap;
  display: flex;
  flex-direction: row;

  ${mediaMax(mediaQueries.small)} {
    width: 100%;
  }

  ${mediaMax(812)} {
    flex-direction: column;
  }
`;

export const ModalConsentSide = styled.div<{ side?: string }>`
  flex-basis: 50%;
  padding: 2rem;

  ${({ side }) =>
    side && side === "right" && `background-color: ${colors.gray50};`}
`;

export const ModalBoldConsentText = styled.div`
  p {
    margin-top: 0.5em;
    font-weight: ${scaffolding.bold};
  }
`;

export const ModalConsentButtons = styled.div(rtl`
  button {
    float: right;
  }

  ${mediaMax(500)} {
    flex-direction: column;
  }
`);

export const ModalConsentAgreeContainer = styled.div`
  flex-grow: 100;
  ${rtl`margin-right: 1em;`}

  button {
    width: 100%;
  }
`;

export const ModalDisagreeButtons = styled.div`
  ${mediaMax(500)} {
    flex-grow: 100;
    ${rtl`margin-right: 1em;`}

    button {
      width: 100%;
    }
  }
`;

export const ModalConsentFineprint = styled.div`
  p {
    font-size: ${typography.fontSizeSmall};
  }
`;

export const ModalAppError = styled(Modal).attrs(() => ({
  role: "alertdialog",
}))`
  & > div {
    padding: 1.5rem;
    max-width: 500px;
    width: 90%;
    animation: fadeIn 0.4s;
    background: ${colors.white};
  }
`;

export const ModalLoader = styled(Modal)`
  text-align: center;

  p {
    margin-bottom: 1em;
  }
`;

export const ModalForm = styled.form`
  ${modalStyles}
  position: absolute;
  border: ${scaffolding.gridBorderColor} 1px solid;
  margin: 0 auto;
  max-height: 80%;
  width: fit-content;
  height: fit-content;
  block-size: fit-content;
  z-index: ${zIndexes.modal};
  padding: ${scaffolding.pagePadding};
  background: ${colors.white};
  animation: fadeIn 0.2s;
  top: 50%;
  ${rtl`left: 50%;`}
  ${rtl`transform: translate(-50%, -50%);`}
`;

export const ModalPsaMobileWrapper = styled.div(rtl`
  width: 100%;
  padding-right: 16px;
`);

export const ModalPsaButton = styled(Button).attrs(() => ({
  "data-testid-a": "styled-PsaButton",
}))(rtl`
  float: right;
`);

export const ModalPsaLimitText = styled.div(rtl`
  float: right;
  margin: 10px;
`);

export const ModalButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ModalOverlay = styled.div(rtl`
  z-index: ${zIndexes.overlay};
  position: absolute;
  height: 100%;
  left: 0;
  right: 0;
  background: ${tagsInConversation.overlayColorRBG};

  ${mediaMax(812)} {
    overflow: auto;
  }
`);

export const ModalOverlayWrapper = styled.div`
  position: absolute;
  top: 50vh;
  width: 100%;
  padding: 5% 0;
  transform: translateY(-50%);
  height: 100%;

  ${mediaMax(mediaQueries.small)} {
    padding: 0;
  }
`;

export const ModalHeader = styled.header`
  display: flex;
  justify-content: space-between;

  *:only-child {
    ${rtl`margin-left: auto;`}
  }
`;

export const ImagesModalWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  max-width: 45rem;
  max-height: 80vh;
  aspect-ratio: 1/1;
`;

type ImagesModalFigureType = { index: number };
export const ImagesModalFigure = styled.figure<ImagesModalFigureType>`
  display: inline-block;
  position: relative;
  height: 100%;
  width: 100%;
  margin: 0;
  transition: transform 0.5s ease-in-out;

  @media (prefers-reduced-motion) {
    transition: none;
  }

  ${({ index }) => `transform: translate3d(${index * -100}%, 0, 0)`}
`;

export const ImagesModalImage = styled.img`
  object-fit: contain;
  max-height: 75vh;
  height: 100%;
  width: 100%;
`;

export const ImagesModalNav = styled.nav`
  display: flex;
  justify-content: space-between;
`;

export const IFInfoModal = styled(CustomModal)`
  display: inline-block;
  overflow: hidden;
  font-size: calc(${typography.fontSizeXsmall} + 0.4vw);

  > div:first-child {
    position: relative;
    margin-top: 3%;

    ${mediaMax(mediaQueries.small)} {
      width: 100%;
      min-height: 100%;
      margin: 0;

      .input_form_modal_nav {
        position: absolute;
        ${rtl`left: 0;`}
        ${rtl`right: 0;`}
        bottom: 24px;
      }
    }
  }
`;

export const TimeWindowWrapper = styled.div`
  text-align: center;
`;
export const TimeWindowIconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const TimeWindowIcon = styled.div`
  background: #f2f2fc;
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 60px;
`;

export const TimeWindowParagraph = styled.p`
  margin: 1em 0;
`;

export const CaregiverCenteredDiv = styled.div`
  text-align: center;
  padding-top: 2rem;
`;

export const CaregiverModal = styled(CustomModal)`
  ${Modal}
  max-width: 100%;
`;

export const CaregiverStyledDiv = styled.div`
  padding-bottom: 3rem;
  padding-top: 0.2rem;
`;

export const CaregiverNextButton = styled(Button).attrs(() => ({
  "data-testid": "add-caregiver-next-button",
}))``;
