import React, { useState } from "react";
import { Icon } from "@netmedi/frontend-design-system";
import { FormattedMessage } from "react-intl";
import { TitleAndBody, Dismiss } from "./Modal.types";
import { endpoints, post } from "common/utils/api";
import { focusOn } from "common/components/Form/utils";
import { Form } from "react-final-form";
import useWindowSize from "shared/hooks/useWindowSize";
import {
  CaregiverCenteredDiv,
  CaregiverModal,
  CaregiverNextButton,
  CaregiverStyledDiv,
  ModalNav,
} from "./Modal.styles";
import { CustomButton } from "client/components/WelcomeClient/AppDownload/AppDownload.styles";
import {
  CaregiverFormContainer,
  Clear,
} from "client/components/Profile/Profile.styles";
import AddCaregiverFormFields from "client/components/Profile/Caregivers/AddCaregiverFormFields";
import { SiteSettings } from "common/utils/holvikaari";

export type AddCaregiverModalProps = Partial<TitleAndBody> & {
  type: "add_caregiver_modal";
  dismiss: () => void;
};

interface AddCaregiverBodyProps {
  handleOnClick: () => void;
}

const AddCaregiverPage = ({
  handleOnClick,
}: Pick<AddCaregiverBodyProps, "handleOnClick">) => {
  return (
    <div>
      <CaregiverCenteredDiv>
        <FormattedMessage tagName="h2" id={"add_caregiver.heading"} />
        <CaregiverStyledDiv>
          <FormattedMessage
            tagName="span"
            id={"modal.add_caregiver_modal.page_1_description"}
          />
        </CaregiverStyledDiv>
      </CaregiverCenteredDiv>
      <ModalNav className="add_caregiver_modal_nav">
        <CaregiverNextButton onClick={handleOnClick}>
          <FormattedMessage tagName="span" id={"modal.continue"} />
        </CaregiverNextButton>
      </ModalNav>
    </div>
  );
};

const InviteCaregiverPage = ({
  handleOnClick,
}: Pick<AddCaregiverBodyProps, "handleOnClick">) => {
  const submit = async (values: any) => {
    try {
      const response = await post(endpoints.caregiver_invites.create, {
        email: values.email,
        phone: values.phone,
      });

      if (response.errors) {
        // TODO: handle errors
      } else {
        handleOnClick();
      }
    } catch (error) {
      // TODO: handle errors
    }
  };

  return (
    <div>
      <Form
        initialValues={{ email: "", emailConfirmation: "", phone: "" }}
        onSubmit={() => {
          // Do nothing and let the form submission happen via the browser
        }}
      >
        {({ handleSubmit, form }) => (
          <>
            <CaregiverFormContainer
              onSubmit={event => {
                // Run the React Final Form submit handler
                handleSubmit(event);
                event.preventDefault();

                // If there are validation errors, focus on the first one
                if (form.getState().hasValidationErrors) {
                  focusOn(
                    "input[aria-invalid=true], [aria-invalid=true] input",
                  );
                } else {
                  // If there are no validation errors, submit the form
                  submit(form.getState().values);
                }
              }}
            >
              <FormattedMessage
                tagName="h2"
                id={"modal.add_caregiver_modal.invite_caregiver"}
              />
              <FormattedMessage
                tagName="span"
                id={"modal.add_caregiver_modal.page_2_description"}
              />
              <AddCaregiverFormFields />
              <ModalNav className="add_caregiver_modal_nav">
                <CaregiverNextButton submit>
                  <Icon align="left" size="medium" name="send" />
                  <FormattedMessage
                    tagName="span"
                    id={"invites.new.send_invite"}
                  />
                </CaregiverNextButton>
              </ModalNav>
              <Clear />
            </CaregiverFormContainer>
          </>
        )}
      </Form>
    </div>
  );
};

const InviteSentPage = ({
  handleOnClick,
}: Pick<AddCaregiverBodyProps, "handleOnClick">) => {
  return (
    <div>
      <CaregiverCenteredDiv>
        <FormattedMessage
          tagName="h2"
          id={"modal.add_caregiver_modal.invitation_sent"}
        />
        <CaregiverStyledDiv>
          <FormattedMessage
            tagName="span"
            id={"modal.add_caregiver_modal.page_3_description"}
            values={{ product_name: SiteSettings.product_name }}
          />
        </CaregiverStyledDiv>
      </CaregiverCenteredDiv>
      <ModalNav className="add_caregiver_modal_nav">
        <CaregiverNextButton onClick={handleOnClick}>
          <FormattedMessage tagName="span" id={"modal.confirm_cap"} />
        </CaregiverNextButton>
      </ModalNav>
    </div>
  );
};

const AddCaregiverModal = ({
  dismiss,
}: Omit<AddCaregiverModalProps, "type"> & Dismiss) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { innerWidth } = useWindowSize();
  const isSmallScreen = innerWidth < 640;

  const handleOnClick = () => {
    currentPage < 3 ? setCurrentPage(currentPage + 1) : dismiss();
  };

  return (
    <CaregiverModal
      onClick={(e: any) => e.stopPropagation()}
      role="dialog"
      fullScreen={isSmallScreen}
      narrow
    >
      <CustomButton
        position="absolute"
        right={24}
        top={24}
        margin={0}
        padding={0}
        color="#000"
        type="ghost"
        onClick={() => dismiss()}
      >
        <Icon name="remove" size="small" />
      </CustomButton>
      {currentPage === 1 && <AddCaregiverPage handleOnClick={handleOnClick} />}
      {currentPage === 2 && (
        <InviteCaregiverPage handleOnClick={handleOnClick} />
      )}
      {currentPage === 3 && <InviteSentPage handleOnClick={handleOnClick} />}
    </CaregiverModal>
  );
};

export default AddCaregiverModal;
