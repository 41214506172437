import styled from "styled-components";
import rtl from "styled-components-rtl";
import {
  scaffolding,
  colors,
  typography,
} from "@netmedi/frontend-design-system/dist/styles/variables";
import { Header } from "common/components/Page";
import rem from "@netmedi/frontend-design-system/dist/styles/styleHelpers";
import { Icon } from "@netmedi/frontend-design-system";
import { FormFieldInput } from "common/components/Form/FormField.styles";

const pageLoadingStyles = `
  opacity: 0.3;
  pointer-events: none;
`;

const styledDataTableAttrs = () => ({
  "data-testid": "data-table",
});

const tdBorderStyles = `
  border-top: ${scaffolding.gridBorderColor} 1px solid;
`;

const separateBorderStyles = `
  border-collapse: separate;
  border-spacing: 0 0.5rem;
`;

const StyledDataTable = styled.table.attrs(styledDataTableAttrs)<{
  pageLoading: boolean;
  separateBorders?: boolean;
}>`
  ${({ separateBorders }) => separateBorders && separateBorderStyles}
  width: 100%;
  font-size: ${typography.fontSizeMedium};
  transition: opacity 0.2s;
  margin-top: 0.5rem;

  th {
    ${rtl`text-align: left;`}
    padding: 0.5rem;
    font-weight: 400;
    font-size: ${rem(16)};
  }

  td {
    padding: 0.5rem;
    ${({ separateBorders }) => !separateBorders && tdBorderStyles}
    vertical-align: middle;
  }

  tr {
    transition: background 0.2s;
  }

  ${({ pageLoading }) => pageLoading && pageLoadingStyles}
`;

export const StyledInput = styled(FormFieldInput).attrs(() => ({
  "data-testid": "search-input",
}))`
  ${rtl`
    margin-left: 4px;
    padding-left: 2rem;
  `}

  width: 100%;

  &:focus {
    outline: 1px solid ${colors.blue700};
  }
`;

export const StyledHeader = styled(Header)`
  border: 0;
`;

export const HeaderNav = styled.div`
  ${rtl`float: right;`}
  display: flex;
  align-items: center;
`;

export const SearchWrapperContainer = styled.div`
  position: relative;
  display: inline-block;
  font-size: ${rem(14)};
`;

export const SearchIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  margin-top: -12px;
  ${rtl`left: 0.4rem;`}
  color: ${colors.gray400};
`;

export const StyledChildren = styled.div`
  padding-bottom: 1rem;
`;

export const NoData = styled.div.attrs(() => ({
  "data-testid": "no-data",
}))`
  padding: 2rem;
  text-align: center;
  color: ${colors.gray700};
`;

export const HeaderContent = styled.span.attrs(() => ({
  "data-testid": "data-table-header-content",
}))`
  display: flex;
  align-items: center;
`;

type SortArrowsProps = {
  sortArrows?: "both" | "up" | "down";
  sortDirection?: "asc" | "desc";
};

const sortArrowsStyles = `
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  margin-bottom: 2px;
`;

export const SortArrows = styled.span<SortArrowsProps>`
  display: inline-block;
  ${rtl`margin-left: 8px;`}

  ${({ sortArrows, sortDirection }) =>
    sortArrows === "both" &&
    `
      &:after, &:before {
        ${sortArrowsStyles}
      }

      &:before {
        border-bottom: 5px solid ${
          sortDirection === "asc" ? colors.gray950 : colors.gray400
        };
      }

      &:after {
        border-top: 5px solid ${
          sortDirection === "desc" ? colors.gray950 : colors.gray400
        };
      }
    `}

  ${({ sortArrows, sortDirection }) =>
    sortArrows === "up" &&
    `
      &:before {
        ${sortArrowsStyles}
        border-bottom: 5px solid ${
          sortDirection === "asc" ? colors.gray950 : colors.gray400
        };
      }
    `}

    ${({ sortArrows, sortDirection }) =>
    sortArrows === "down" &&
    `
      &:after {
        ${sortArrowsStyles}
        border-top: 5px solid ${
          sortDirection === "desc" ? colors.gray950 : colors.gray400
        };
      }
    `}
`;

export default StyledDataTable;
