/* eslint-disable max-lines-per-function */
import React, { useMemo, useState } from "react";
import { CheckBox, Icon, Spinner } from "@netmedi/frontend-design-system";
import useAbsences, { Absence } from "./useAbsences";
import {
  ActionContainer,
  HeaderActions,
  TableActionContainer,
  TextMuted,
} from "./Absences.styles";
import useI18nTranslate from "common/hooks/useI18nTranslate";
import AutoSortDataTable from "common/components/DataTable/AutoSortDataTable";
import dayjs from "dayjs";
import Button from "@netmedi/frontend-design-system/dist/components/Button";
import { useSelector } from "react-redux";
import { selectStaffMapById } from "common/reducers/staff";
import { absenceRoutes } from "./Absences";
import getApiClient from "common/utils/api2/getApiClient";
import { selectUser } from "common/reducers/user";
import { useHistory } from "react-router";

const AbsenceList: React.FC = () => {
  const [showOnlyOwnAbsences, setShowOnlyOwnAbsences] = useState(false);
  const {
    loading: loadingAbsences,
    absences: allAbsences,
    removeAbsence,
  } = useAbsences();
  const staff = useSelector(selectStaffMapById);
  const user = useSelector(selectUser);
  const t = useI18nTranslate();
  const history = useHistory();

  // Filter absences based on tab
  const absences = useMemo(
    () =>
      allAbsences?.filter(
        absence =>
          !showOnlyOwnAbsences ||
          absence.absent_person_id === +user.id ||
          absence.substitute_person_id === +user.id,
      ),
    [allAbsences, showOnlyOwnAbsences, user.id],
  );

  const deleteAbsence = async (id: number) => {
    await getApiClient().deleteAbsence({ id }).delete();
    removeAbsence(id);
  };

  const sortByLastName =
    (idFieldName: "absent_person_id" | "substitute_person_id") =>
    (a: Absence, b: Absence) => {
      const aLastName = staff[a[idFieldName]].last_name;
      const bLastName = staff[b[idFieldName]].last_name;
      return aLastName.localeCompare(bLastName);
    };

  const toggleFilterOwnAbsences = () => {
    setShowOnlyOwnAbsences(oldVal => !oldVal);
  };

  return (
    <div data-testid="AbsenceList">
      <h1>{t("absences.title")}</h1>

      {loadingAbsences ? (
        <Spinner />
      ) : (
        <>
          <TableActionContainer>
            <HeaderActions>
              <CheckBox
                name="filter-own"
                onChange={toggleFilterOwnAbsences}
                id="filter-own"
                checked={showOnlyOwnAbsences}
                label={t("absences.filter_own_absences")}
                title={t("absences.filter_own_absences")}
              />
              <Button
                onClick={() => history.push(absenceRoutes.new)}
                type="secondary"
                size="small"
              >
                {t("absences.new")}
              </Button>
            </HeaderActions>
          </TableActionContainer>

          <AutoSortDataTable
            data={absences ?? []}
            columns={[
              {
                key: "absent_person_id",
                name: "absences.absent_person",
                value: (absence: Absence) =>
                  staff[absence.absent_person_id]?.full_name,
                sorter: sortByLastName("absent_person_id"),
              },
              {
                key: "substitute_person_id",
                name: "absences.substitute_person",
                value: (absence: Absence) =>
                  staff[absence.substitute_person_id]?.full_name,
                sorter: sortByLastName("substitute_person_id"),
              },
              {
                key: "begin_date",
                name: "attributes.begin_date",
                value: (absence: Absence) =>
                  dayjs(absence.begin_date).format("L"),
                sorter: (a, b) => dayjs(a.begin_date).diff(dayjs(b.begin_date)),
              },
              {
                key: "end_date",
                name: "attributes.end_date",
                value: (absence: Absence) =>
                  dayjs(absence.end_date).format("L"),
                sorter: (a, b) => dayjs(a.end_date).diff(dayjs(b.end_date)),
              },
              {
                key: "description",
                name: "attributes.description",
                sortable: false,
                value: a =>
                  a.description ?? (
                    <TextMuted>{t("absences.no_description")}</TextMuted>
                  ),
              },
              {
                key: "active",
                name: "attributes.status",
                value: (absence: Absence) =>
                  absence.active
                    ? t("absences.is_active")
                    : t("absences.not_active"),
              },
              {
                key: "actions",
                name: "",
                format: false,
                sortable: false,
                value: (absence: Absence) => (
                  <ActionContainer>
                    {/* EDIT BUTTON */}
                    <Button
                      onClick={() =>
                        history.push(absenceRoutes.edit(absence.id))
                      }
                      size="small"
                      type="ghost"
                      color="red"
                      title={t("absences.edit")}
                    >
                      <Icon name="edit_16px" />
                    </Button>

                    {/* DELETE BUTTON */}
                    <Button
                      aria-label={t("absences.delete")}
                      onClick={() => {
                        confirm(t("absences.confirm_delete")) &&
                          absence.id &&
                          deleteAbsence(absence.id);
                      }}
                      size="small"
                      type="ghost"
                    >
                      <Icon name="trashcan_16px" />
                    </Button>
                  </ActionContainer>
                ),
              },
            ]}
            loading={loadingAbsences}
            emptyMessage={"absences.no_absencs"}
            defaultSort={{ column: "begin_date", dir: "desc" }}
          />
        </>
      )}
    </div>
  );
};

export default AbsenceList;
